import {
  Artist,
  BaseStyleProps,
  CloudinaryImage,
  StringMap,
  User,
  Variant,
  VariantRequest,
} from '@/lib/interfaces';
import {
  CLIENT_ADMIN_WAITLIST_VIEW_URL,
  CLIENT_ARTIST_WAITLIST_PAGE_URL,
  CLIENT_EXHIBITION_PORTAL_URL,
  CLIENT_GALERY_WAITLIST_PAGE_URL,
  CLIENT_USER_COLLECTION_URL,
  userRoleNames,
} from '@/lib/utils/constants';
import { NextRouter } from 'next/router';
import qs from 'qs';

export const arrayEquals = (a: any[], b: any[]) => {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index])
  );
};

export const toggleIdFromArray = (arr: number[], id: number) => {
  if (arr.includes(id)) {
    return [...arr.filter((v) => v !== id)];
  }
  return [...arr, id];
};

export const randomNumberUpTo = (num: number) =>
  Math.floor(Math.random() * num);

export const genQs = (queryObject: StringMap): string =>
  qs.stringify(queryObject, { addQueryPrefix: true, encodeValuesOnly: true });

export const parseBaseStylePropsClassNames = (props: BaseStyleProps) =>
  Object.values(props).join(' ');

export const loginRedirectQs = () =>
  genQs({
    redirect: window.location.pathname,
  });

export const dollarfy = (money: any) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(money);

export const shrinkLargeNum = (num: number): string => {
  const million = 1000000;
  const thousands = 1000;
  if (num >= million) {
    return `$${(num / million).toFixed(2)}M`;
  } else if (num >= thousands) {
    return `$${(num / thousands).toFixed(2)}K`;
  }
  return `$${num}`;
};

export const percentify = (percent: number) => {
  const isPositive = percent > -1 ? '+' : '';
  return `${isPositive}${percent}%`;
};

export const calcPercentDiff = (mv: number, pp: number): number => {
  if (mv === 0 && pp === 0) return 0;
  const percent = ((Number(mv) - Number(pp)) / Number(pp)) * 100;

  return Math.trunc(percent);
};

export const getVariantImage = (variant: Variant) => {
  return variant?.image?.[0]?.url;
};

// export const getVariantSmallImage = (variant: Variant) => {
//   return variant?.image?.[0]?.formats?.small?.url;
// };
// export const getVariantThumbnail = (variant: Variant) => {
//   return variant?.image?.[0]?.formats?.thumbnail?.url;
// };

export const getOptimizedImage = (image: CloudinaryImage) => {
  return (
    // image.formats?.thumbnail?.url ??
    image.formats?.small?.url ??
    image.formats?.medium?.url ??
    image.formats?.large?.url ??
    image.url
  );
};

export const getLargeImage = (image: CloudinaryImage) => {
  return image.formats?.large?.url ?? image.url;
};

export const getVariantImageAltText = (variant: Variant) => {
  return variant?.image?.[0]?.alternativeText ?? '';
};

export const getVariantRequestImageAltText = (variant: VariantRequest) => {
  return variant?.image?.alternativeText ?? '';
};

export const getArtistImage = (artist: Artist) => {
  return artist?.image?.url;
};

// export const getArtistSmallImage = (artist) => {
//   return artist?.image?.formats?.small?.url;
// };

// export const getArtistThumbnail = (artist) => {
//   return artist?.image?.formats?.thumbnail?.url;
// };

// export const getOptimizedArtistImage = (artist) => {
//   return (
//     getArtistThumbnail(artist) ||
//     getArtistSmallImage(artist) ||
//     getArtistImage(artist)
//   );
// };

// export const getArtistImageAltText = (artist) => {
//   return artist?.image?.alternativeText || '';
// };

export const getReleaseYear = (dateString: string) => dateString?.split('-')[0];

export const getCurrentYear = new Date().getFullYear();

// export const totalPieces = (artCollection) =>
//   Object.keys(artCollection).reduce((acc, cv) => {
//     acc += artCollection[cv].length;
//     return acc;
//   }, 0);

// export const totalEstimatedValue = (artCollection) =>
//   Object.keys(artCollection).reduce(
//     (acc, cv) =>
//       (acc += artCollection[cv].reduce(
//         (ac, v) =>
//           (ac += v.variant.marketValue || v.variant.releasePrice * 1.2),
//         0
//       )),
//     0
//   );

// export const totalEstimateValueFromList = (collectionPieceList) => {
//   return collectionPieceList.reduce((acc, cv) => {
//     acc += cv?.variant?.marketValue || cv.variant.releasePrice * 1.2;
//     return acc;
//   }, 0);
// };

// export const totalAcquisitionPriceFromList = (collectionPieceList) =>
//   collectionPieceList.reduce((acc, cv) => {
//     acc += cv.price || 0;
//     return acc;
//   }, 0);

// export const totalAcquisitionPrice = (artCollection) =>
//   Object.keys(artCollection).reduce(
//     (acc, cv) =>
//       (acc += artCollection[cv].reduce((ac, v) => (ac += v.price), 0)),
//     0
//   );

// export const gainOrLossPercent = ({ value, price }) =>
//   Math.round((value / price - 1) * 100) || 0;

export const routeUserAfterLogin = ({
  user,
  router,
  redirect,
}: {
  user: User;
  router: NextRouter;
  redirect: string | null | undefined;
}) => {
  if (redirect) return router.push(redirect);

  switch (user?.role?.name) {
    case userRoleNames.authenticated:
      return router.push(CLIENT_USER_COLLECTION_URL);
    case userRoleNames.artist:
      return router.push(CLIENT_ARTIST_WAITLIST_PAGE_URL);
    case userRoleNames.gallery:
      return router.push(CLIENT_GALERY_WAITLIST_PAGE_URL);
    case userRoleNames.artDealer:
      return router.push(CLIENT_EXHIBITION_PORTAL_URL);
    case userRoleNames.admin:
      return router.push(CLIENT_ADMIN_WAITLIST_VIEW_URL('kai'));
  }
};

// export const alphabeticalSort = (a, b) => {
//   if (a < b) {
//     return -1;
//   }
//   if (a > b) {
//     return 1;
//   }
//   return 0;
// };

// export const releaseDateSort = (a, b) => {
//   if (b?.releaseDate < a?.releaseDate) {
//     return -1;
//   }
//   if (b?.releaseDate > a?.releaseDate) {
//     return 1;
//   }
//   return 0;
// };

// export const searchFilter = (arrItem, filter) =>
//   filter?.length ? arrItem.toLowerCase().includes(filter?.toLowerCase()) : true;

// export const editionText = (val) => {
//   const genText = (v) => (v === '1' ? 'Original' : `Edition of ${v}`);
//   return val ? genText(val) : `Limited Edition`;
// };

export const isArtist = (user: User | undefined) =>
  user?.role.name === userRoleNames.artist;
export const isAuthenticated = (user: User | undefined) =>
  user?.role.name === userRoleNames.authenticated;
export const isGallery = (user: User | undefined) =>
  user?.role.name === userRoleNames.gallery;
export const isArtDealer = (user: User | undefined) =>
  user?.role.name === userRoleNames.artDealer;
export const isAdmin = (user: User | undefined) =>
  user?.role.name === userRoleNames.admin;

export const numToStringFormatter = (
  total: number,
  copy: string,
  pluralSymbol: string = 'S'
) => {
  return `${total} ${copy}${total > 1 ? pluralSymbol : ''}`;
};
