import {
  ApiResponse,
  Artist,
  ArtistApplicationFormValues,
  ArtistWaitlistFormValues,
  ArtistWaitListSettings,
  CollectionPiece,
  CountResponse,
  CpAndVRForOneArtistResponse,
  DistinctArtistsInCollectionResponse,
  Gallery,
  MultiSearchResponse,
  NonStandardApiResponse,
  User,
  Variant,
  VariantAndVRForOneArtistResponse,
  VariantRequest,
  VariantWithShopify,
  WaitList,
  WatchlistVariantsApiResponse,
} from '@/lib/interfaces';
import { toggleIdFromArray } from '@/lib/utils/v2HelperMethods';
import {
  CMS_ARITST_WAITLISTS_USERS_COUNT_URL,
  CMS_ARITST_WAITLISTS_USERS_URL,
  CMS_ARTIST_APPLICATIONS_URL,
  CMS_ARTISTS_MY_ARTIST_URL,
  CMS_ARTISTS_URL,
  CMS_ARTISTS_WAITLIST_SETTINGS_URL,
  CMS_ARTWORKS_MY_COLLECTORS_OWN_URL,
  CMS_COLLECTION_PIECES_URL,
  CMS_COLLECTORS_FOR_SINGLE_ARTWORK_URL,
  CMS_EVENT_LEADS_URL,
  CMS_GALLERIES_MY_GALLERY_URL,
  CMS_GALLERIES_URL,
  CMS_GALLERY_WAITLISTS_USERS_COUNT_URL,
  CMS_GALLERY_WAITLISTS_USERS_URL,
  CMS_HOMEPAGE_CAROUSEL_URL,
  CMS_MULTI_SEARCH_URL,
  CMS_MY_COLLECTORS_URL,
  CMS_PUBLIC_ARTISTS_WITH_MPL_URL,
  CMS_REQUEST_VERIFICATION_URL,
  CMS_SCAN_VARIANT_URL,
  CMS_SHOWS_URL,
  CMS_USER_COLLECTION_PIECES_DISTINCT_ARTISTS_URL,
  CMS_USER_COLLECTION_PIECES_TOTALS_URL,
  CMS_USER_CP_AND_VR_FOR_ONE_ARTIST_IN_PROFILE_URL,
  CMS_USER_CP_AND_VR_FOR_ONE_ARTIST_URL,
  CMS_USER_FIND_COLLECTION_PIECES_COUNT_IN_USER_PROFILE_URL,
  CMS_USER_FIND_COLLECTION_PIECES_COUNT_URL,
  CMS_USER_FIND_COLLECTION_PIECES_IN_USER_PROFILE_URL,
  CMS_USER_FIND_COLLECTION_PIECES_URL,
  CMS_USER_FIND_VARIANT_REQUESTS_COUNT_IN_USER_PROFILE_URL,
  CMS_USER_FIND_VARIANT_REQUESTS_COUNT_URL,
  CMS_USER_FIND_VARIANT_REQUESTS_URL,
  CMS_USER_INFO_URL,
  CMS_USER_MY_ARTIST_WAITLISTS_URL,
  CMS_USER_PROFILE_DISTINCT_ARTISTS_URL,
  CMS_USER_PROFILE_VARIANT_REQUEST_DISTINCT_ARTISTS_URL,
  CMS_USER_PROFILE_VARIANT_REQUESTS_URL,
  CMS_USER_VARIANT_REQUEST_DISTINCT_ARTISTS_URL,
  CMS_USER_VARIANT_REQUEST_TOTALS_URL,
  CMS_USER_WAITLIST_FOR_ARTIST_URL,
  CMS_VARIANT_REQUEST_URL,
  CMS_VARIANTS_URL,
  CMS_VARIANTS_WATCHLIST_URL,
  CMS_VARIANTS_WITH_SHOPIFY_OBJECT_URL,
  CMS_VERIFY_USER_PROFILE_URL,
  CMS_VERIFY_USER_URL,
  COLLECTOR_URL,
  COUNT_URL,
  NAME_URL,
  PIECES_URL,
  SUM_URL,
  TOTALS_URL,
} from '@/utils/constants';
import { postData } from '@/utils/strapiHelpers';
import axios, { AxiosResponse } from 'axios';
import serverCookies from 'cookie';
import Cookies from 'js-cookie';
import { GetServerSidePropsContext } from 'next';

// /* HTTP Request Helpers */
export const BACKEND_API = process.env.NEXT_PUBLIC_API_URL;
export const genJwtToken = () => ({
  headers: {
    Authorization: `Bearer ${Cookies.get('token')}`,
  },
});

export const genJwtTokenServerSide = ({ jwt }: { jwt: string }) => ({
  headers: {
    Authorization: `Bearer ${jwt}`,
  },
});

// /* ReactQuery fetch wrappers*/
export const fetchWithToken = (url: string) => {
  return axios.get(url, genJwtToken()).then((res) => res.data);
};

export const fetcher = (url: string) => {
  return axios.get(url).then((res) => res.data);
};

export const universalGetRequest = (
  url: string,
  ctx: GetServerSidePropsContext | undefined = undefined,
  auth: boolean = true
) => {
  if (!auth) return fetcher(url);
  if (ctx)
    return serverSideGetRequest({
      ctx,
      url,
    });
  else return fetchWithToken(url);
};

// /* GLOBAL REQUESTS */

export const findGalleries = async (query: string = ''): Promise<Gallery[]> => {
  const url = BACKEND_API + CMS_GALLERIES_URL + query;
  const response: ApiResponse<Gallery[]> = await universalGetRequest(
    url,
    undefined,
    false
  );
  return response.data;
};

export const findMyGalleryObject = async (
  ctx: GetServerSidePropsContext | undefined = undefined
) => {
  const url = BACKEND_API + CMS_GALLERIES_MY_GALLERY_URL;
  const response = await universalGetRequest(url, ctx);
  return response.data;
};

export const getGalleryUsersWaitlistListCount = async (
  ctx: GetServerSidePropsContext | undefined = undefined
) => {
  const url = BACKEND_API + CMS_GALLERY_WAITLISTS_USERS_COUNT_URL;
  const response: ApiResponse<CountResponse> = await universalGetRequest(
    url,
    ctx
  );
  return response.data.count;
};

export const getGalleryWaitlistSums = async (
  ctx: GetServerSidePropsContext | undefined = undefined,
  query: string = ''
) => {
  const url = BACKEND_API + CMS_GALLERY_WAITLISTS_USERS_URL + SUM_URL + query;
  const response = await universalGetRequest(url, ctx);
  return response.data;
};

// /* CLIENT SIDE REQUESTS*/

export const getCountWaitList = async (query: string) => {
  const response = await axios.get(
    BACKEND_API + CMS_USER_WAITLIST_FOR_ARTIST_URL + COUNT_URL + query,
    genJwtToken()
  );
  return response.data;
};

export const getArtistUsersWaitlists = async (
  query: string,
  ctx: GetServerSidePropsContext | undefined = undefined
) => {
  const url = BACKEND_API + CMS_ARITST_WAITLISTS_USERS_URL + query;
  const response: ApiResponse<WaitList[]> = await universalGetRequest(url, ctx);

  return response;
};

export const getGalleryUsersWaitlist = async (query: string) => {
  const url = BACKEND_API + CMS_GALLERY_WAITLISTS_USERS_URL + query;

  const response = await universalGetRequest(url);

  return response;
};

export const getArtworksMyCollectorsOwn = async (
  query: string = '',
  ctx: GetServerSidePropsContext | undefined = undefined
) => {
  const url = BACKEND_API + CMS_ARTWORKS_MY_COLLECTORS_OWN_URL + query;

  const response = await universalGetRequest(url, ctx);

  return response;
};

export const getCollectorsForSingleArtwork = async (
  id: number,
  query: string = '',
  ctx: GetServerSidePropsContext | undefined = undefined
) => {
  const url = BACKEND_API + CMS_COLLECTORS_FOR_SINGLE_ARTWORK_URL(id) + query;

  if (ctx)
    return serverSideGetRequest({
      ctx,
      url,
    });
  else return fetchWithToken(url);
};

export const getCollectorsForArtist = async (
  query: string = '',
  ctx: GetServerSidePropsContext | undefined = undefined
) => {
  const url = BACKEND_API + CMS_MY_COLLECTORS_URL + query;

  const response = await universalGetRequest(url, ctx);

  return response;
};

export const updateCollectionPiece = async (formData: FormData, id: number) => {
  const res = await axios.put(
    BACKEND_API + CMS_COLLECTION_PIECES_URL + '/' + `${id}`,
    formData,
    genJwtToken()
  );
  return res.data;
};

export const addEventLead = async (values: any) => {
  const res = await axios.post(
    BACKEND_API + CMS_EVENT_LEADS_URL,
    postData(values)
  );
  return res.data.data;
};

export const postArtworkWaitlist = (values: ArtistWaitlistFormValues) => {
  return axios.post(
    BACKEND_API + CMS_USER_WAITLIST_FOR_ARTIST_URL,
    postData(values),
    genJwtToken()
  );
};

export const updateArtworkWaitlist = (
  values: ArtistWaitlistFormValues,
  waitlistId: number
) => {
  return axios.put(
    BACKEND_API + CMS_USER_WAITLIST_FOR_ARTIST_URL + `/${waitlistId}`,
    postData(values),
    genJwtToken()
  );
};

export const updateUser = (user: any) => {
  return axios.put(BACKEND_API + CMS_USER_INFO_URL, user, genJwtToken());
};

export const removeCollectionPiece = async (id: number) => {
  const url = BACKEND_API + CMS_COLLECTION_PIECES_URL + '/' + id;
  return axios.delete(url, genJwtToken());
};

export const removeVariantRequest = async (id: number) => {
  const url = BACKEND_API + CMS_VARIANT_REQUEST_URL + '/' + id;
  return axios.delete(url, genJwtToken());
};

// /* SERVER SIDE REQUESTS*/
export const redirectServerSide = (url: string) => {
  return {
    redirect: {
      destination: url,
      permanent: false,
    },
  };
};

export const serverSideGetRequest = async ({
  ctx,
  url,
}: {
  ctx: GetServerSidePropsContext;
  url: string;
}) => {
  const cookie = ctx?.req?.headers?.cookie;
  if (!cookie) {
    return false;
  }

  const { token } = serverCookies.parse(cookie);

  if (!token) return false;

  try {
    const res = await axios.get(url, genJwtTokenServerSide({ jwt: token }));
    return res.data;
  } catch (error) {
    console.log({ error });
    return false;
  }
};

export const getCollectorCollectionPiecesForArtist = async (
  author: string | number,
  query: string = '',
  ctx: GetServerSidePropsContext | undefined = undefined
) => {
  const url =
    BACKEND_API + CMS_MY_COLLECTORS_URL + COLLECTOR_URL + `/${author}` + query;
  const response: ApiResponse<CollectionPiece[]> = await universalGetRequest(
    url,
    ctx
  );

  return response;
};

export const getCollectorsCountForSingleArtwork = async (
  id: number,
  ctx: GetServerSidePropsContext | undefined = undefined
) => {
  const url =
    BACKEND_API + CMS_COLLECTORS_FOR_SINGLE_ARTWORK_URL(id) + COUNT_URL;

  if (ctx)
    return serverSideGetRequest({
      ctx,
      url,
    });
  else return fetchWithToken(url);
};

export const getCollectorsForArtistCount = async (
  ctx: GetServerSidePropsContext | undefined = undefined
) => {
  const url = BACKEND_API + CMS_MY_COLLECTORS_URL + COUNT_URL;

  const response: ApiResponse<CountResponse> = await universalGetRequest(
    url,
    ctx
  );
  return response.data.count;
};

export const getCollectionPiecesForArtistCount = async (
  ctx: GetServerSidePropsContext
) => {
  const url = BACKEND_API + CMS_MY_COLLECTORS_URL + PIECES_URL + COUNT_URL;
  const response: ApiResponse<CountResponse> = await universalGetRequest(
    url,
    ctx
  );

  return response.data.count;
};

export const getTotalCollectionPriceAndMarketValue = async (
  ctx: GetServerSidePropsContext
) => {
  const url = BACKEND_API + CMS_MY_COLLECTORS_URL + PIECES_URL + TOTALS_URL;
  const response = await universalGetRequest(url, ctx);

  return response.data;
};

export const verifyUserProfileServerSide = async (url_handle: string) => {
  const response = await axios.get(
    BACKEND_API + CMS_VERIFY_USER_PROFILE_URL + `/${url_handle}`
  );
  return response.data;
};

export const getSingleVariantById = async (
  id: string | number,
  query: string = ''
): Promise<Variant> => {
  const url = BACKEND_API + CMS_VARIANTS_URL + `/${id}` + query;
  const response: ApiResponse<Variant> = await universalGetRequest(
    url,
    undefined,
    false
  );

  return response.data;
};

export const getSingleVariantByHandle = async (url_handle: string) => {
  const response = await axios.get(
    BACKEND_API + CMS_VARIANTS_URL + NAME_URL + `/${url_handle}`
  );
  return response.data;
};

export const getSingleArtistByName = async (
  url_handle: string,
  query: string = ''
): Promise<Artist> => {
  const url =
    BACKEND_API + CMS_ARTISTS_URL + NAME_URL + '/' + url_handle + query;
  const response: ApiResponse<Artist> = await universalGetRequest(
    url,
    undefined,
    false
  );
  return response.data;
};

export const getSingleArtistWaitListSettings = async (
  query: string
): Promise<ArtistWaitListSettings[]> => {
  const url = BACKEND_API + CMS_ARTISTS_WAITLIST_SETTINGS_URL + query;
  const response: ApiResponse<ArtistWaitListSettings[]> =
    await universalGetRequest(url, undefined, false);
  return response.data;
};

export const findOneCollectionPiece = async (
  id: number | string,
  query: string = '',
  ctx: GetServerSidePropsContext | undefined = undefined
) => {
  const url = BACKEND_API + CMS_COLLECTION_PIECES_URL + '/' + id + query;
  const response: ApiResponse<CollectionPiece> = await universalGetRequest(
    url,
    ctx
  );
  return response.data;
};

export const getArtistUsersWaitlistListCount = async (
  ctx: GetServerSidePropsContext
) => {
  const url = BACKEND_API + CMS_ARITST_WAITLISTS_USERS_COUNT_URL;
  const response: ApiResponse<CountResponse> = await universalGetRequest(
    url,
    ctx
  );
  return response.data.count;
};

export const findMyArtistObject = async (
  ctx: GetServerSidePropsContext | undefined = undefined
): Promise<Artist> => {
  const url = BACKEND_API + CMS_ARTISTS_MY_ARTIST_URL;
  const response: ApiResponse<Artist> = await universalGetRequest(url, ctx);

  return response.data;
};

export const getArtistWaitlistSums = async (
  ctx: GetServerSidePropsContext,
  query = ''
) => {
  const url = BACKEND_API + CMS_ARITST_WAITLISTS_USERS_URL + SUM_URL + query;
  const response = await universalGetRequest(url, ctx);

  return response.data;
};

/***************************
 *
 *
 * PUBLIC USER FUNCTIONS
 *
 *
 **************************/

export const getGalleries = async (query: string = '') => {
  const url = BACKEND_API + CMS_GALLERIES_URL + query;

  return fetcher(url);
};

export const getUserProfileVariantRequests = async (
  id: number,
  query: string = ''
) => {
  const url =
    BACKEND_API + CMS_USER_PROFILE_VARIANT_REQUESTS_URL + `/${id}` + query;

  return fetcher(url);
};

export const getUserCollectionPiecesCountInUserProfile = async (
  id: number,
  query: string = ''
) => {
  const url =
    BACKEND_API +
    CMS_USER_FIND_COLLECTION_PIECES_COUNT_IN_USER_PROFILE_URL +
    `/${id}` +
    query;

  return fetcher(url);
};

export const getUserProfileCollectionPieces = async (
  id: number,
  query: string = ''
) => {
  const url =
    BACKEND_API +
    CMS_USER_FIND_COLLECTION_PIECES_IN_USER_PROFILE_URL +
    `/${id}` +
    query;

  return universalGetRequest(url, undefined, false);
};

export const getUserVariantRequestsCountInUserProfile = async (
  id: number,
  query: string = ''
) => {
  const url =
    BACKEND_API +
    CMS_USER_FIND_VARIANT_REQUESTS_COUNT_IN_USER_PROFILE_URL +
    `/${id}` +
    query;

  return fetcher(url);
};

export const getUserDistinctVariantRequestArtistsInUserProfile = async (
  id: number,
  query: string = ''
) => {
  const url =
    BACKEND_API +
    CMS_USER_PROFILE_VARIANT_REQUEST_DISTINCT_ARTISTS_URL +
    `/${id}` +
    query;
  return fetcher(url);
};

export const getUserDistinctArtistsInProfile = async (id: number) => {
  const url = BACKEND_API + CMS_USER_PROFILE_DISTINCT_ARTISTS_URL + '/' + id;
  const response = await universalGetRequest(url, undefined, false);
  return response.data;
};

export const getHomePageCarousel = async (query = '') => {
  const url = BACKEND_API + CMS_HOMEPAGE_CAROUSEL_URL + query;
  const response = await universalGetRequest(url, undefined, false);
  return response.data;
};

export const getArtists = async (
  query = ''
): Promise<ApiResponse<Artist[]>> => {
  const url = BACKEND_API + CMS_ARTISTS_URL + query;
  const response = await universalGetRequest(url, undefined, false);
  return response;
};

export const pub_findArtistsWithMarketPlaceListings = async (
  query = ''
): Promise<ApiResponse<Artist[]>> => {
  const url = BACKEND_API + CMS_PUBLIC_ARTISTS_WITH_MPL_URL + query;
  const response = await universalGetRequest(url, undefined, false);
  return response;
};

export const getArtistById = async (id: number) => {
  const url = BACKEND_API + CMS_ARTISTS_URL + '/' + id;
  const response: ApiResponse<Artist> = await universalGetRequest(
    url,
    undefined,
    false
  );
  return response.data;
};

export const getShows = async (query = '') => {
  const response = await axios.get(BACKEND_API + CMS_SHOWS_URL + query);
  return response.data;
};

export const getShowsCount = async (query = '') => {
  const response = await axios.get(
    BACKEND_API + CMS_SHOWS_URL + COUNT_URL + query
  );
  return response.data;
};

export const getVariants = async (
  query = ''
): Promise<ApiResponse<Variant[]>> => {
  const response: AxiosResponse<ApiResponse<Variant[]>> = await axios.get(
    BACKEND_API + CMS_VARIANTS_URL + query
  );
  return response.data;
};

export const pub_multiSearch = async (
  query = ''
): Promise<ApiResponse<MultiSearchResponse>> => {
  const url = BACKEND_API + CMS_MULTI_SEARCH_URL + query;
  const response = universalGetRequest(url, undefined, false);
  return response;
};

export const scanArtwork = async ({ image }: { image: string }) => {
  const response = await axios.post(BACKEND_API + CMS_SCAN_VARIANT_URL, {
    image,
  });
  return response.data;
};

export const scanArtworkFormData = async (fd: FormData) => {
  const response = await axios.post(BACKEND_API + CMS_SCAN_VARIANT_URL, fd);
  return response.data.data;
};

export const getVariantsWithShopifyObject = async (
  query = ''
): Promise<ApiResponse<VariantWithShopify[]>> => {
  const url = BACKEND_API + CMS_VARIANTS_WITH_SHOPIFY_OBJECT_URL + query;
  const response = await universalGetRequest(url, undefined, false);

  return response;
};

export const getVariantsCount = async (query = '') => {
  const url = BACKEND_API + CMS_VARIANTS_URL + COUNT_URL + query;
  const response = await universalGetRequest(url, undefined, false);
  return response.data.count;
};

export const getArtistsCount = async (query = '') => {
  const response = await axios.get(
    BACKEND_API + CMS_ARTISTS_URL + COUNT_URL + query
  );
  return response.data;
};

export const postArtistApplication = async (
  values: ArtistApplicationFormValues
) => {
  const res = await axios.post(
    BACKEND_API + CMS_ARTIST_APPLICATIONS_URL,
    postData(values)
  );
  const data: ApiResponse<ArtistApplicationFormValues> = res.data;
  return data.data;
};

/***************************
 *
 *
 * AUTH USER FUNCTIONS
 *
 *
 **************************/

export const updateMe = (userInfo: any) => {
  return axios.put(BACKEND_API + CMS_USER_INFO_URL, userInfo, genJwtToken());
};

export const findOneVariantRequest = async (
  id: number | string,
  query: string = '',
  ctx: GetServerSidePropsContext | undefined = undefined
) => {
  const url = BACKEND_API + CMS_VARIANT_REQUEST_URL + '/' + id + query;
  const response: ApiResponse<VariantRequest> = await universalGetRequest(
    url,
    ctx
  );
  return response.data;
};

export const updateVariantRequest = async (formData: FormData, id: number) => {
  const res = await axios.put(
    BACKEND_API + CMS_VARIANT_REQUEST_URL + '/' + id,
    formData,
    genJwtToken()
  );
  return res.data;
};

export const createVariantRequest = async (formData: FormData) => {
  const res = await axios.post(
    BACKEND_API + CMS_VARIANT_REQUEST_URL,
    formData,
    genJwtToken()
  );
  return res.data;
};
export const createCollectionPiece = async (formData: FormData) => {
  const res = await axios.post(
    BACKEND_API + CMS_COLLECTION_PIECES_URL,
    formData,
    genJwtToken()
  );
  return res.data;
};

export const getUsersActiveWaitlists = async (
  query: string = '',
  ctx: GetServerSidePropsContext | undefined = undefined
) => {
  const url = BACKEND_API + CMS_USER_MY_ARTIST_WAITLISTS_URL + query;
  const response: ApiResponse<WaitList[]> = await universalGetRequest(url, ctx);
  return response;
};

export const requestVerification = async (variant: Variant) => {
  const res = await axios.post(
    BACKEND_API + CMS_REQUEST_VERIFICATION_URL,
    { variant },
    genJwtToken()
  );
  return res.data;
};

export const verifyUser = async (
  ctx: GetServerSidePropsContext | undefined = undefined
): Promise<User> => {
  const url = BACKEND_API + CMS_VERIFY_USER_URL;

  return universalGetRequest(url, ctx);
};

export const getUserCollectionPieces = async (
  query: string = '',
  ctx: GetServerSidePropsContext | undefined = undefined
) => {
  const url = BACKEND_API + CMS_USER_FIND_COLLECTION_PIECES_URL + query;
  return universalGetRequest(url, ctx);
};

export const getUserVariantRequests = async (
  query: string = '',
  ctx: GetServerSidePropsContext | undefined = undefined
) => {
  const url = BACKEND_API + CMS_USER_FIND_VARIANT_REQUESTS_URL + query;
  const response = await universalGetRequest(url, ctx);
  return response;
};

export const getUserTotalCollectionPiecePriceAndMarketValue = async (
  query: string = '',
  ctx: GetServerSidePropsContext | undefined = undefined
) => {
  const url = BACKEND_API + CMS_USER_COLLECTION_PIECES_TOTALS_URL + query;
  const response = await universalGetRequest(url, ctx);
  return response.data;
};

export const getUserTotalVariantRequestPriceAndMarketValue = async (
  query: string = '',
  ctx: GetServerSidePropsContext | undefined = undefined
) => {
  const url = BACKEND_API + CMS_USER_VARIANT_REQUEST_TOTALS_URL + query;
  const response = await universalGetRequest(url, ctx);
  return response.data;
};

export const getUserDistinctVariantRequestArtists = async (
  query = '',
  ctx: GetServerSidePropsContext | undefined = undefined
) => {
  const url =
    BACKEND_API + CMS_USER_VARIANT_REQUEST_DISTINCT_ARTISTS_URL + query;
  const response: ApiResponse<string[]> = await universalGetRequest(url, ctx);
  return response.data;
};

export const getUserDistinctArtistsInCollection = async (
  ctx: GetServerSidePropsContext | undefined = undefined
) => {
  const url = BACKEND_API + CMS_USER_COLLECTION_PIECES_DISTINCT_ARTISTS_URL;
  const response: ApiResponse<DistinctArtistsInCollectionResponse> =
    await universalGetRequest(url, ctx);
  return response.data;
};

export const auth_getCollectionPiecesAndReqForOneArtist = async (
  query: string = '',
  ctx: GetServerSidePropsContext | undefined = undefined
) => {
  const url = BACKEND_API + CMS_USER_CP_AND_VR_FOR_ONE_ARTIST_URL + query;
  const response: NonStandardApiResponse<CpAndVRForOneArtistResponse> =
    await universalGetRequest(url, ctx);
  return response;
};

export const pub_getCollectionPiecesAndReqForOneArtistInUserProfile = async (
  id: number,
  query: string = ''
) => {
  const url =
    BACKEND_API + CMS_USER_CP_AND_VR_FOR_ONE_ARTIST_IN_PROFILE_URL(id) + query;
  const response: NonStandardApiResponse<VariantAndVRForOneArtistResponse> =
    await universalGetRequest(url, undefined, false);
  return response;
};

export const getUserCollectionPiecesCount = async (
  query: string = '',
  ctx: GetServerSidePropsContext | undefined = undefined
) => {
  const url = BACKEND_API + CMS_USER_FIND_COLLECTION_PIECES_COUNT_URL + query;
  const response: ApiResponse<CountResponse> = await universalGetRequest(
    url,
    ctx
  );
  return response.data.count;
};

export const getUserVariantRequestsCount = async (
  query: string = '',
  ctx: GetServerSidePropsContext | undefined = undefined
) => {
  const url = BACKEND_API + CMS_USER_FIND_VARIANT_REQUESTS_COUNT_URL + query;
  const response: ApiResponse<CountResponse> = await universalGetRequest(
    url,
    ctx
  );
  return response.data.count;
};

export const getUserVariantsWatchlist = async (
  ctx: GetServerSidePropsContext | undefined = undefined
) => {
  const url = BACKEND_API + CMS_VARIANTS_WATCHLIST_URL;
  const res: ApiResponse<WatchlistVariantsApiResponse[]> =
    await universalGetRequest(url, ctx, true);
  return res.data;
};

export const updateUserVariantsWatchlist = async (
  arr: number[],
  id: number
) => {
  const favoriteVariants = toggleIdFromArray(arr, id);
  return axios.put(
    BACKEND_API + CMS_VARIANTS_WATCHLIST_URL,
    postData({ favoriteVariants }),
    genJwtToken()
  );
};
